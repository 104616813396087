<template>
  <SfLoader
    class="m-dl__wrapper"
    :loading="loading"
    background="rgba(255, 255, 255, 0.4)"
  >
    <template #loader>
      <div class="m-dl">
        <div class="m-dl__regot m-dl__regot1"></div>
        <div class="m-dl__regot m-dl__regot2"></div>
        <div class="m-dl__regot m-dl__regot3"></div>
        <div class="m-dl__regot m-dl__regot4"></div>
      </div>
    </template>
  </SfLoader>
</template>

<script>
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'

export default {
  name: 'MDottedLoader',
  components: {
    SfLoader
  },
  props: {
    loading: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfLoader.scss";

.m-dl {
  font-size: 20px;
  position: relative;
  width: var(--spacer-49);
  height: var(--spacer-13);
  margin: 100px auto;

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.m-dl__regot {
  width: var(--spacer-13);
  height: var(--spacer-13);
  border-radius: 0.5em;
  background: var(--orange);
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.m-dl__regot1, .m-dl__regot2 {
  left: 0;
}

.m-dl__regot3 { left: var(--spacer-18); }

.m-dl__regot4 { left: var(--spacer-32); }

@keyframes show {
  from { transform: scale(0.001); }
  to { transform: scale(1); }
}

@keyframes remove {
  from { transform: scale(1); }
  to { transform: scale(0.001); }
}

@keyframes shift {
  to { transform: translateX(var(--spacer-18)) }
}

.m-dl__regot1 {
  animation-name: show;
}

.m-dl__regot2, .m-dl__regot3 {
  animation-name: shift;
}

.m-dl__regot4 {
  animation-name: remove;
}

</style>
