<template>
  <div id="not-found">
    <div class="m-not-found error">
      <SfHeading :level="1" :title="$t('Redirection to the payment page.')" class="error__message" />
      <div class="loader">
        <MDottedLoader :loading="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { SfHeading } from '@storefront-ui/vue';
import MDottedLoader from 'theme/components/molecules/m-dotted-loader.vue';

export default {
  name: 'PaymentRedirect',
  components: {
    MDottedLoader,
    SfHeading
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/breakpoints';
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.error {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    margin: var(--spacer-82) auto var(--spacer-68);
    min-height: 165px;
    position: relative;
    width: 100%;

    @media (min-width: $tablet-min) {
      min-height: px2rem(261);
    }
  }
}

::v-deep {
  .error__message {
    --heading-padding: 0;
    margin: 20px 0 0;

    .sf-heading__title {
      @include header-title;
      text-align: center;
    }
  }
}
</style>
